import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { plainToClass, Transform, Type } from 'class-transformer';
import { BaseCandidate } from '../BaseCandidate';

export class OrdersEnd {
  @Type(/* istanbul ignore next */ () => Number)
  candidateId!: number;
  @Type(/* istanbul ignore next */ () => Number)
  orderId!: number;
  firstName!: string | null;
  lastName!: string | null;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  orderEndDate!: Date;
  trackingNumber!: string | null;
  candidate!: BaseCandidate;

  constructor(partial?: Partial<OrdersEnd>) {
    if (partial) {
      Object.assign(this, plainToClass(OrdersEnd, partial));
    }
  }
}
