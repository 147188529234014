import { TableDataProvider } from '@/components/Table/models/TableDataProvider';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchOrdersEndDto } from '@/models/Dtos/searchOrdersEndDto';
import { OrdersEnd } from '@/models/Entities/MaterializedViews/OrdersEnd';
import { OrdersEndingTableData } from './OrdersEndingTableData';

export class OrdersEndingDataProvider extends TableDataProvider<
  OrdersEndingTableData,
  OrdersEnd,
  SearchOrdersEndDto
> {
  protected transformer = (t: OrdersEnd): OrdersEndingTableData =>
    new OrdersEndingTableData(t);

  protected queryHandler = async (
    searchDto: SearchOrdersEndDto
  ): Promise<SearchResponseDto<OrdersEnd>> => {
    const results: SearchResponseDto<OrdersEnd> = await this.store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      { path: 'ordersEnd', query: searchDto, model: OrdersEnd }
    );
    return results;
  };
}
