var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "flex items-center focus:outline-none" }, [
    _c(
      "button",
      {
        staticClass: "text-gray-500 focus:outline-none",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.toggleVisibility.apply(null, arguments)
          },
        },
      },
      [_c("icon-component", { attrs: { name: "eye", width: 22, height: 22 } })],
      1
    ),
    _vm.visible
      ? _c("div", { staticClass: "ml-2" }, [_vm._v(_vm._s(_vm.updateFields))])
      : _c("div", { staticClass: "ml-2 text-base" }, [_vm._v("•••-••-••••")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }