
import IconComponent from '@/components/IconComponent.vue';
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { OrdersEndingDataProvider } from '@/components/Table/OrdersEnding/OrdersEndingDataProvider';
import { OrdersEndingTableData } from '@/components/Table/OrdersEnding/OrdersEndingTableData';
import { ordersEndingTableConfigBuilder } from '@/components/Table/OrdersEnding/OrdersEndingTableConfigBuilder';
import QuickFilterComponent from '@/components/Table/QuickFilterComponent.vue';
import TableFilterComponent from '@/components/Table/TableFilterComponent.vue';
import FetchMixin from '@/mixins/FetchMixin';
import { SearchOrdersEndDto } from '@/models/Dtos/searchOrdersEndDto';
import { TlnImportLog } from '@/models/Entities/Imports/TlnImportLog';
import { OrdersEnd } from '@/models/Entities/MaterializedViews/OrdersEnd';
import Routes from '@/router/Routes';
import { formatDate } from '@/services/formatService';
import { Component, Mixins, Vue } from 'vue-property-decorator';

@Component<TlnImportPage>({
  components: {
    AsyncTableComponent,
    TableFilterComponent,
    QuickFilterComponent,
    LinkElementComponent,
    IconComponent
  }
})
export default class TlnImportPage extends Mixins(Vue, FetchMixin) {
  routes = Routes;
  latestDateTime = '...';
  searchService = new TableSearchService<
    OrdersEndingTableData,
    OrdersEnd,
    never,
    SearchOrdersEndDto
  >(
    this.$store,
    new OrdersEndingDataProvider(this.$store),
    { relations: ['candidate'] },
    { defaultSortProperty: 'orderEndDate' },
    ordersEndingTableConfigBuilder,
    {
      filters: [],
      quickFilters: []
    }
  );
  async mounted(): Promise<void> {
    await this.queryLatestDate();
    await this.searchService.queryData();
  }
  async queryLatestDate(): Promise<void> {
    const date = await this.getLatest('tln-import-log', TlnImportLog);
    if (date) {
      this.latestDateTime = formatDate(date);
    }
  }
}
