import DateTableElementComponent from '@/components/Table/Elements/DateTableElementComponent.vue';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { TableConfigBuilder } from '@/components/Table/models/TableConfigBuilder';
import { SearchOrdersEndDto } from '@/models/Dtos/searchOrdersEndDto';
import { OrdersEnd } from '@/models/Entities/MaterializedViews/OrdersEnd';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import { OrdersEndingTableData } from './OrdersEndingTableData';
import ShowSocialSecurityComponent from '@/components/Table/Elements/ShowSocialSecurityComponent.vue';

export const ordersEndingTableConfigBuilder: TableConfigBuilder<
  OrdersEndingTableData,
  OrdersEnd,
  never,
  SearchOrdersEndDto
> = (): ITableConfiguration<OrdersEndingTableData, OrdersEnd, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowCandidate: OrdersEndingTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowCandidate.name,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowCandidate.id }
              }
            };
          }
        },
        sortKey: 'lastName'
      },
      {
        columnTitle: 'SSN',
        render: {
          componentRef: ShowSocialSecurityComponent,
          componentProps: (
            rowCandidate: OrdersEndingTableData
          ): Record<string, unknown> => {
            return {
              ssnString: rowCandidate.ssn
            };
          }
        }
      },
      {
        columnTitle: 'Orders End',
        render: {
          componentRef: DateTableElementComponent,
          componentProps: (rowData: OrdersEndingTableData) => {
            return {
              date: rowData.end
            };
          }
        },
        sortKey: 'orderEndDate'
      },
      {
        columnTitle: 'Days Left',
        render: 'daysLeft',
        sortKey: 'orderEndDate'
      },
      {
        columnTitle: 'Tracking Number',
        render: 'trackingNumber',
        sortKey: 'trackingNumber'
      }
    ]
  };
};
