
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';

@Component<ShowSocialSecurityComponent>({ components: { IconComponent } })
export default class ShowSocialSecurityComponent extends Vue {
  @Prop({
    default: ''
  })
  ssnString!: string;

  visible = false;

  get updateFields(): string {
    let [field1, field2, field3] = ['', '', ''];
    if (this.ssnString && this.ssnString.length === 9) {
      field1 = this.ssnString.substring(0, 3);
      field2 = this.ssnString.substring(3, 5);
      field3 = this.ssnString.substring(5, 9);
    }
    return field1 + '-' + field2 + '-' + field3;
  }

  toggleVisibility() {
    this.visible = !this.visible;
  }
}
